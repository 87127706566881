<template>
  <div>
    <ModalFormGeneric :id="id" :title="$t('tra.cha.t')" :btnText="$t('btn.c')" btnIcon="acc" :btnDisabled="!valAmount||!f.confirm"
        @submit="onSubmit" @reset="onReset" @show="onShow" ref="chaWindow">
      <b-row>
        <b-col cols="12">
          <b-form-group :label="$t('tra.cha.am')" :label-for="id + 'FormAm'" class="text-left">
            <b-form-spinbutton :id="id + 'FormAm'"
                v-model="f.amount" :formatterFn="getMoney" @wheel.native="onWheel"
                :min="defMin" :max="defMax" :step="defStep" :state="valAmount" required />
            <b-form-invalid-feedback :state="valAmount">
              {{ $t('tra.cha.w') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group :label="$t('traM.pay.cch')" :label-for="id + 'FormCn'" class="text-left">
            <b-checkbox :id="id + 'FormCn'" v-model="f.confirm" :state="f.confirm" required>
              <i18n path="traM.pay.cct" tag="span">
                <template #link>
                  <b-link :to="{name: 'AnuTos'}" target="_blank" class="text-primary">{{ $t('traM.pay.ccl') }}</b-link>
                </template>
              </i18n>
            </b-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group :label="$t('traM.pay.sah')" :label-for="id + 'FormSa'" class="text-left">
            <b-checkbox :id="id + 'FormSa'" v-model="f.save" :state="null">
              {{ $t('traM.pay.sad') }}
            </b-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-row>
        <b-col cols="*">
          <p class="mb-0 pb-0 text-justify">{{ $t('traM.pay.txt') }}</p>
        </b-col>
      </b-form-row>
    </ModalFormGeneric>
    <!-- <ModalYesNoGeneric :id="id + 'FormCnf'" :title="$t('traM.pay.hdr')"
        :btnTextReset="$t('btn.a')" btnIconReset="a"
        :btnTextSubmit="$t('btn.red')" btnIconSubmit="ln" btnVariantSubmit="primary"
        @submit="onRedirect()" /> -->
  </div>
</template>

<script>
import ModalFormGeneric from '@/components/ModalFormGeneric';
import { REST } from '@/components/RestCall';

export default {
  data() {
    return {
      f: {
        senderUuid4: null,
        amount: null,
        currency: null,
        save: false,
        confirm: false,
      },
      defAmount: 2000,
      defMin: 500,
      defMax: 10000,
      defStep: 500,
      linkUrl: null,
    };
  },
  computed: {
    valAmount() {
      if (this.f.amount === null) return null;
      let val = parseInt(this.f.amount);
      if (isNaN(val)) {
        return false;
      }
      return val >= this.defMin && val <= this.defMax; // between 5 EUR and 100 EUR
    },
  },
  mounted() {
    this.onReset();
  },
  methods: {
    onWheel(v) {
      if (v.deltaY < 0) {
        if (this.f.amount == this.defMax) return;
        this.f.amount += this.defStep;
      } else {
        if (this.f.amount == this.defMin) return;
        this.f.amount -= this.defStep;
      }
    },
    onShow() {
      this.f.senderUuid4 = this.$store.state.oLogin.uuid;
      this.f.currency = this.chaCurrency;
      this.f.amount = this.defAmount;
    },
    onSubmit() {
      if (this.f.senderUuid4 != null && this.valAmount && this.f.save !== null && this.f.confirm === true) {
        let self = this;
        REST.post('/accounts/'+this.chaUuid+'/deposit', this.f)
              // .catch( // FAKE DATA
              //   () => {
              //     return Promise.resolve({ d: { data: {
              //       'senderUuid4': '00000000-0000-4000-a000-000000000001',
              //       'receiverUuid4': this.f.senderUuid4,
              //       'amount': this.f.amount,
              //       'currency': this.f.currency,
              //       'type': 'CHARGE',
              //       'tax': null,
              //       'ref': null,
              //       'uuid4': '33332222-1111-4444-bbbb-aaaabbbbcccc',
              //       'state': 'PLACED',
              //       'receiverName': null,
              //       'tip': null,
              //       'createdAt': '2021-02-09 16:20',
              //       'data': {'link': 'https://testpay.anures.com/index.html'}
              //     }}});
              //   })
            .then(async function(resp) {
                // show for user
                self.$store.commit('showSuccess',resp.d.message);
                self.linkUrl = resp.d.data.data.link;
                // self.$bvModal.show(self.id + 'FormCnf');
                location.href = self.linkUrl;
            }).catch(e => {
                this.$store.commit('showWarn',e.message)
            }).finally(() => {
                this.$refs.chaWindow.hide();
            });
      }
    },
    onRedirect() {
      //window.open(this.linkUrl);
      window.location(this.linkUrl);
    },
    onReset() {
      this.f.senderUuid4 = null;
      this.f.amount = null;
      this.f.currency = null;
      this.f.confirm = false;
      this.f.save = false;
    },
    getMoney(a) {
      if (a == null || this.f.currency == null) return '';
      return this.$n(a/100, { key: 'c', currency: this.f.currency });
    },
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    chaUuid: {
      type: String,
      default: null,
    },
    chaCurrency: {
      type: String,
      default: null,
    },
  },
  components: {
    ModalFormGeneric,
    // ModalYesNoGeneric: () => import('@/components/ModalYesNoGeneric'),
  },
}
</script>
